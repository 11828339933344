<template>
  <div>
    <TitleWrapper
      :filter-option="false"
      title="THEME_SETT"
      tooltip-title="THEME_SETT"
      :display-breadcrumb="true"
    />
    <div
      class="min-h-screen bg-bg-color-white pt-7 mt-7 px-3 md:px-7 border rounded-t-lg border-border-color-lighter"
    >
      <div class="lg:gap-3 md:w-80">
        <UiSingleSelect
          title="Select Color"
          :options="themes"
          :value="selectedTheme"
          class="w-100"
          @change="changeWebTheme"
        />
      </div>
      <div class="lg:gap-3 md:w-80">
        <UiSingleSelect
          title="Select Language"
          :options="longLang"
          :value="selectedLanguage.long"
          @change="languageSelected"
        />
      </div>
      <button
        class="mt-5 focus:outline-none focus:shadow-md ltr:text-base rtl:text-sm text-white font-rubik flex w-full md:w-40 py-2 justify-center primary-button"
        @click="apply()"
      >
        Save Changes
      </button>
    </div>
  </div>
</template>

<script>
import TitleWrapper from '@components/TitleWrapper.vue'
import { mapState } from 'vuex'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import ROUTE from '@/src/constants/route-constants.js'
/* MIXINS */
import themeAndLangMixin from '@src/mixins/theme-and-lang-mixins'

export default {
  components: {
    UiSingleSelect,
    TitleWrapper,
  },
  mixins: [themeAndLangMixin],
  beforeRouteLeave(to, from, next) {
    if (this.settingsEditted) {
      const answer = window.confirm('Do you really want to leave? you have unsaved changes!')
      if (answer) {
        this.settingsEditted = false
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  },
  data() {
    return {
      dashboard: 'dashboard',
      themeData: [],
      selectedTheme: JSON.parse(localStorage.getItem('theme'))
        ? JSON.parse(localStorage.getItem('theme')).name
        : 'green',
      selectedLanguage: this.$store.state.base.curLanguage,
      applyChanges: false,
      settingsEditted: false,
      defaultTheme: this.selectedTheme,
      defaultLang: this.selectedLanguage,
    }
  },
  computed: {
    ...mapState({
      languages: (state) => state.base.languages,
    }),
  },
  watch: {
    selectedLanguage: 'onSelectedLangChange',
  },
  mounted() {
    this.defaultTheme = this.selectedTheme
    this.defaultLang = this.selectedLanguage
  },
  beforeUnmount() {
    if (!this.applyChanges) {
      this.languageSelected(this.defaultLang.long)
      this.changeWebTheme(this.defaultTheme)
    }
  },
  methods: {
    apply() {
      this.applyChanges = true
      this.settingsEditted = false
      /* SETTING THEME */
      this.defaultTheme = this.selectedTheme
      this.saveSelectedTheme(this.selectedTheme)
      /* SETTING LANG */
      this.defaultLang = this.selectedLanguage
      this.saveLanguageToLocalStorage(this.selectedLanguage)
      /* TOAST */
      this.$store.commit(
        'toast/NEW',
        { type: 'success', message: this.$t(`toast.Successfully Updated`) },
        { root: true },
      )
      this.$forceUpdate()
    },
    onSelectedLangChange(newLang) {
      this.$store.dispatch('base/loadInitialLang', newLang)
    },
    languageSelected(value) {
      this.selectedLanguage = this.selectLang(value)
      this.compareSettings()
    },

    compareSettings() {
      if (this.selectedTheme === this.defaultTheme && this.selectedLanguage === this.defaultLang) {
        this.settingsEditted = false
      } else {
        this.settingsEditted = true
      }
    },
    changeWebTheme(value) {
      this.selectedTheme = value
      this.selectTheme(value)
      this.compareSettings()
    },
  },
}
</script>
